import styled from "styled-components"

const MainSlide = styled.div`
    display: flex;
    align-items: center;
    width: 85vw;
    height: 80vh;
    position: relative;
`

const TreeImage = styled.img`
    height: 90vh;
    width: auto;
    display: block;
    margin: auto;
    position: relative;
    bottom: -10px;
    transform: translate(-0%,-10%);
    float: left;
    @media screen  and (max-width: 760px){
        transform: translate(-20%,-30%);
        height: 160%;
    }
`

const MainTitle = styled.h1`
    font-family: Amaranth, sans-serif;
    font-size: 2rem;
    margin-top: 0px;
    text-align: right;
    color: black;
    transform: translate(-30%,100%);
    @media screen  and (max-width: 760px){
        transform: translate(-120%,200%);
        font-size: 3rem;
    }
`

const MainTitleDu = styled.h1`
    font-family: Amaranth, sans-serif;
    font-size: 2rem;
    margin-top: 0px;
    text-align: right;
    color: black;
    transform: translate(-55%,134%);
    @media screen  and (max-width: 760px){
        transform: translate(-120%,200%);
        font-size: 3rem;
    }
`

const MainLogo = styled.img`
    width: 38%;
    padding-top: 4%;
    padding-bottom: 4%;
    padding-left: 4%;
    padding-right: 1%;
    @media screen  and (max-width: 760px){
        height: 60px;
        transform: translate(17%,0)
    }
`

const SecondLogo = styled.img`
    width:100%;
`

export {
    MainSlide,
    TreeImage,
    MainTitle,
    MainTitleDu,
    MainLogo,
    SecondLogo
}