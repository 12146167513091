import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton } from 'react-bootstrap';
import { NavLink, NavList } from './Styles';
import { paragraphe, titre } from './lang';
import { MainLogo, SecondLogo } from './MainSlide';
import OrderBook from './OrderBook';
import logo from '../img/eclosions_logo_rouge_kaki.png'
import logo_2 from '../img/eclosions_bioinfused.png'
import bcropLogo_fr from '../img/sti_Bcorp.png'
import bcropLogo_eng from '../img/sti_Bcorp.png'
import bcropLogo_du from '../img/sti_Bcorp.png'


export default function SideNavigation({ lang, setLang }) {

    console.log('Lang', lang)
    const bcropLogo = lang == 'fr' ? bcropLogo_fr : lang == 'en' ? bcropLogo_eng : bcropLogo_du
    const navItems = [
        {
            title: titre(lang, 13),
            content: <>
                <Dropdown.Item onClick={() => setLang('en')}>English</Dropdown.Item>
                <Dropdown.Item onClick={() => setLang('fr')}>Français</Dropdown.Item>
                <Dropdown.Item onClick={() => setLang('du')}>Deutsch</Dropdown.Item>
            </>
        },
        {
            title: titre(lang, 10),
            content: <div className='p-3'>
                {paragraphe(lang, 10)}
            </div>
        },
        {
            title: titre(lang, 14),
            content: <OrderBook lang={lang} />
        },
        {
            title: titre(lang, 15),
            content: <div className='px-4'>
                {paragraphe(lang, 11)}
            </div>
        },
    ]
    function getBioInfusedPdfAndRedirect() {
        if (lang === 'en') {
            window.open('https://bioinfused.my.canva.site/', '_blank');
            //location.href='';

        } else if (lang === 'fr') {
            window.open('/files/Eclosions - bioinfused offer 2023 F.pdf', '_blank');
        } else {
            window.open('/files/Eclosions - bioinfused offer 2023 D.pdf', '_blank');
        }
        return false;
    }


    return (
        <NavList>
            <NavLink>
                <a href="#/step-1">
                    <MainLogo src={logo} href="#/step-1" />
                </a>
            </NavLink>
            <NavLink>
                <a onClick={getBioInfusedPdfAndRedirect} target="_blank" style={{ cursor: 'pointer' }}>
                    <SecondLogo src={logo_2} />
                </a>
            </NavLink>
            {
                navItems.map(item => (
                    <NavLink>
                        <Dropdown className='dropDwonResponsivness'>
                            <DropdownButton key='left' drop='left' title={item.title}>
                                {item.content}
                            </DropdownButton>
                        </Dropdown>
                    </NavLink>
                ))
            }
            <NavLink>
                <img src={bcropLogo} style={{ width: '75%' }} alt="" usemap="#planetmap" />
                <map id="planetmap" name="planetmap">
                    <area shape="rect" coords="0,0,132,62" alt="Sun" href="https://fr.swisstripleimpact.ch/companies/eclosions---marketing-%26-communication" target="_blank"/>
                    <area shape="rect" coords="150,0,201,62" alt="Sun" href="https://www.bcorporation.net/en-us/find-a-b-corp/company/eclosions" target="_blank"/>
                </map>
            </NavLink>
        </NavList>
    )
}
