import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Impress from './components/Impress'
import Step from './components/Step'
import tree from "./img/treee_with_bird_3.png"
import portrait from './img/michaela.jpeg'
import { Slide, Title, Paragraph, Spacing, FlexDiv, ExitButton } from "./components/Styles"
import { MainSlide, TreeImage, MainTitle, MainTitleDu } from './components/MainSlide'
import { titre, paragraphe } from "./components/lang"
import img from "./img/croix.png"
import SideNavigation from './components/SideNavigation';
import { useMediaQuery, useTheme } from '@material-ui/core';

function App() {
    const [lang, setLang] = React.useState("en");
    const scale = 0.07;
    const theme = useTheme()
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    var steps = [
        {
            x: isMdScreen ? -350 : -380,
            y: isMdScreen ? 300 : -210,
            title: titre(lang, 2),
            paragraphe: paragraphe(lang, 1)
        },
        {
            x: isMdScreen ? -350 : 20,
            y: isMdScreen ? 450 : -200,
            title: titre(lang, 3),
            paragraphe: paragraphe(lang, 2)
        },
        {
            x: isMdScreen ? -350 : 20,
            y: isMdScreen ? 600 : -100,
            title: titre(lang, 4),
            paragraphe: paragraphe(lang, 3)
        },
        {
            x: isMdScreen ? -350 : -380,
            y: isMdScreen ? -150 : -111,
            title: titre(lang, 5),
            paragraphe: paragraphe(lang, 4)
        },
        {
            x: isMdScreen ? -350 : -380,
            y: isMdScreen ? -600 : 80,
            title: titre(lang, 7),
            paragraphe: paragraphe(lang, 6)
        },
        {
            x: isMdScreen ? -350 : -220,
            y: isMdScreen ? -450 : 200,
            title: titre(lang, 8),
            paragraphe: paragraphe(lang, 7)
        },
        {
            x: isMdScreen ? -350 : 20,
            y: isMdScreen ? -300 : 80,
            title: titre(lang, 9),
            paragraphe: paragraphe(lang, 8)
        },
        {
            x: isMdScreen ? -350 : -380,
            y: isMdScreen ? 150 : -17,
            title: titre(lang, 12),
            paragraphe: paragraphe(lang, 9)
        },
    ]

    const isDutch = (lang === 'du' ? true : false);
    console.log("isDutch =" + isDutch);
    let mainTitle;

    if (isDutch) {
        steps = [
            {
                x: isMdScreen ? -350 : -420,
                y: isMdScreen ? 300 : -210,
                title: titre(lang, 2),
                paragraphe: paragraphe(lang, 1)
            },
            {
                x: isMdScreen ? -350 : -20,
                y: isMdScreen ? 450 : -200,
                title: titre(lang, 3),
                paragraphe: paragraphe(lang, 2)
            },
            {
                x: isMdScreen ? -350 : -20,
                y: isMdScreen ? 600 : -100,
                title: titre(lang, 4),
                paragraphe: paragraphe(lang, 3)
            },
            {
                x: isMdScreen ? -350 : -420,
                y: isMdScreen ? -150 : -111,
                title: titre(lang, 5),
                paragraphe: paragraphe(lang, 4)
            },
            {
                x: isMdScreen ? -350 : -420,
                y: isMdScreen ? -600 : 80,
                title: titre(lang, 7),
                paragraphe: paragraphe(lang, 6)
            },
            {
                x: isMdScreen ? -350 : -220,
                y: isMdScreen ? -450 : 200,
                title: titre(lang, 8),
                paragraphe: paragraphe(lang, 7)
            },
            {
                x: isMdScreen ? -350 : -20,
                y: isMdScreen ? -300 : 80,
                title: titre(lang, 9),
                paragraphe: paragraphe(lang, 8)
            },
            {
                x: isMdScreen ? -350 : -420,
                y: isMdScreen ? 150 : -17,
                title: titre(lang, 12),
                paragraphe: paragraphe(lang, 9)
            },
        ]
    }

    if (isDutch) {
        mainTitle = <MainTitleDu>
            {titre(lang, 1)}
        </MainTitleDu>;
    } else {
        mainTitle = <MainTitle>
            {titre(lang, 1)}
        </MainTitle>;
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', zIndex: 5000 }}>
                {/* navigation */}
                <SideNavigation lang={lang} setLang={setLang} />
                {/* main Content */}
                <Impress>
                    <Step x={0} y={0} key={'first-ste'}>
                        <MainSlide>
                            <TreeImage src={tree} alt="main image" />
                            {mainTitle}
                        </MainSlide>
                    </Step>
                    {steps.map((step,i) => (
                        <Step x={step.x} y={step.y} scale={scale} key={'step' + i}>
                            <Slide>
                                <ExitButton
                                    href="#/step-1"
                                    style={{ float: 'right' }}>
                                    <img src={img} />
                                </ExitButton>
                                <Title>{step.title}</Title>
                                <Spacing h={3} />
                                <Paragraph>{step.paragraphe}</Paragraph>
                            </Slide>
                        </Step>
                    ))}
                    <Step x={isMdScreen ? -350 : isDutch ? -20 : 20} y={isMdScreen ? 0 : 0} scale={0.07} key={'laststep'}>
                        <Slide>
                            <ExitButton
                                href="#/step-1"
                                style={{ float: 'right' }}>
                                <img src={img} />
                            </ExitButton>
                            <Title>{titre(lang, 6)}</Title>
                            <Spacing h={1} />
                            <FlexDiv>
                                <img src={portrait} alt='Portrait' />
                                <Paragraph style={{ textAlign: "left", paddingLeft: "4rem", paddingRight: "4rem" }}>
                                    {paragraphe(lang, 5)}
                                </Paragraph>
                            </FlexDiv>
                        </Slide>
                    </Step>
                </Impress>
            </div>
            {/* Footer */}
            <footer>
                <p
                    style={{ fontSize: '12px', position: 'absolute', bottom: '0', left: '1%', fontFamily: 'UbuntuLight' }}
                    className='footerText'>Copyright <a href="#/step-1"
                        style={{ cursor: 'pointer', color: "#bc2749" }}
                    >
                        Eclosions
                    </a> By <a href='http://www.berexia.com' target='_blank' style={{ color: '#7a805d' }}>
                        Berexia
                    </a> 2023
                </p>
            </footer>
        </>
    )
}
export default App